import React from "react";
import PropTypes from "prop-types";
import ImgWrapper from "gatsby-image-wrapper";

const Talk = ({ name, author, date, thumbnail }) => (
	<>
		<div
			className="w-100"
			style={{
				backgroundImage: `url(${thumbnail})`,
				backgroundPosition: "center",
				backgroundSize: "cover",
				minHeight: "300px"
			}}
		/>
		<ul className="list-unstyled pt-3">
			<li>{name}</li>
			<li>{author}</li>
			<li>{date}</li>
		</ul>
	</>
);

Talk.propTypes = {
	name: PropTypes.string.isRequired,
	author: PropTypes.string.isRequired,
	date: PropTypes.string.isRequired,
	thumbnail: PropTypes.string.isRequired
};

export default Talk;
