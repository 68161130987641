import React from "react";
import { ImgWrapper } from "gatsby-image-wrapper";
import { graphql, StaticQuery } from "gatsby";
import megumethod from "../images/mm-basic-logo-white.png"
import Layout from "../components/Layout";
import facebook from "../images/icons/facebook.svg";
import Countdown from "../components/countdown/countdown";
import Talk from "../components/Talk";
import SlidesLive from "../components/SlidesLive";
import Talk1Thumbnail from "../images/talks/38942855.jpg";
import NewsLatter from "../components/NewsLatter";

class Talks extends React.Component {
	constructor(props) {
		super(props);
		const talks = [
			{
				id: 38942855,
				author: "Pavel Bartoš",
				name: "Jak na úspěšnou aplikaci?",
				releaseDate: "Nov 18, 2020 08:30:00",
				thumbnail: Talk1Thumbnail
			}
		];
		this.state = {
			talks,
			currentTalk: this.getCurrentTalk(talks)
		};

		this.appRef = React.createRef();

		this.handleTalkChange = this.handleTalkChange.bind(this);
	}

	componentDidMount() {
		document.title = this.props.data.metadata.title;
		if (window.location.hash) {
			const talkId = this.state.talks.find(
				talk => talk.id == window.location.hash.substring(1)
			);
			this.handleTalkChange(talkId);
		}
	}

	getCurrentTalk(talks) {
		const getCurrentTalk = talks.filter(talk => {
			const currentDate = new Date().getTime();
			const releaseDate = new Date(talk.releaseDate).getTime();
			return currentDate >= releaseDate;
		});
		return getCurrentTalk.slice(-1).pop();
	}

	getPreReleasedTalks() {
		return this.state.talks.filter(talk => {
			const currentDate = new Date().getTime();
			const releaseDate = new Date(talk.releaseDate).getTime();
			return currentDate <= releaseDate;
		});
	}

	getReleasedTalks() {
		return this.state.talks.filter(talk => {
			const currentDate = new Date().getTime();
			const releaseDate = new Date(talk.releaseDate).getTime();
			return currentDate >= releaseDate;
		});
	}

	scrollToAppRef = () => {
		window.scrollTo({
			top: this.appRef.current.offsetTop,
			behavior: "smooth"
		});
	};

	handleTalkChange(talk) {
		this.setState({ currentTalk: talk });
	}

	render() {
		const partnersStyles = {
			marginLeft: "2em",
			marginRight: "2em",
			marginBottom: "4em"
		};

		const { cena_creative_docku } = this.props.data.metadata.event;
		const hasCreativeDock =
			cena_creative_docku &&
			cena_creative_docku.icon &&
			cena_creative_docku.app_name &&
			cena_creative_docku.company_name;
		const { googlePlayImage, appleStoreImage } = this.props.data;

		return (
			<Layout page="talks">

				<hr />
				<div id="video" className="container-heading">
					<h2>
						AppParade Talks - nový online formát pro dobu, kdy se nelze setkat
						offline
					</h2>
				</div>
				<hr />
				<div className="container-fluid">
					<SlidesLive
						key={this.state.currentTalk.id}
						presentationId={this.state.currentTalk.id}
					/>
				</div>
				<div className="container-heading pt-3">
					<h2 className="mt-1 slash">{this.state.currentTalk.name}</h2>
				</div>
				<hr />
				<div className="container-heading">
					<h3 className="mt-1 slash">O AppParade Talks</h3>
				</div>
				<hr />
				<div className="container">
					<div className="row">
						<div className="col">
							<p className="text-lg">
								Třicetiminutové webináře se špičkami v oboru na téma, jak
								vytvořit aplikaci, kterou budou lidé opravdu chtít. To budou
								podzimní AppParade Talks - inspirace online, zdarma a už v
								listopadu a v prosinci. Proč?
							</p>
							<p className="text-lg">
								Protože vás chceme inspirovat, abychom se v roce 2021 mohli
								vidět offline se spoustou dalších skvělých aplikací na soutěžním
								podiu.
							</p>
							<p className="text-lg">
								9 z 10 aplikací totiž zanikne a vývoj je přitom nesmírně drahý.
								Jak vůbec validovat váš nápad? Jak pracovat s uživateli? Zkrátka
								ti, kteří již uspěli, vám poradí, jak ověřit, že má vaše
								aplikace už od začátku ten nejvyšší potenciál.
							</p>
							<p className="text-lg">
								To vše se dozvíte ve webinářích, které bude uvádět CEO MeguMethod
								Vratislav Zima.
							</p>
						</div>
					</div>
				</div>
				<hr />
				<div className="container-heading">
					<h3 className="mt-1 slash">AppParade Talks 1: Pavel Bartoš</h3>
				</div>
				<hr />
				<div className="container">
					<div className="row">
						<div className="col">
							<p className="text-lg">
								Pavel Bartoš je startup mentor, konzultant, facilitátor a Google
								certified trainer. Celý život se pohybuje mezi obchodem a
								marketingem. Se svou firmou Happyend.agency posouvá startupy
								kupředu, soustředí se na inovace ze strategického pohledu a také
								buduje a uvádí nové produkty na trh.
							</p>
							<p className="text-lg">
								I proto patří k těm nejpovolanějším, kteří mohou na AppParade
								Talks mluvit. Chcete, aby vaše aplikace patřila k elitním 10 %,
								které přežijí první rok existence? Nenechte si jeho přednášku
								ujít.
							</p>
							<p className="text-lg">
								<strong>Jak vytvořit aplikaci, kterou budou lidé chtít?</strong>
							</p>
							<p className="text-lg">
								Ten příběh známe všichni. Intenzivně pracujeme na nápadu na
								novou aplikaci, abychom posléze zjistili, že o ni vlastně není
								zájem a nikdo ji nechce. Frustrující. Ale jak z toho ven? Jak
								postupovat jinak, abychom na konci slavili úspěch? Odpovědí může
								být design thinking. Poznejte, jak pracovat s touto metodologií,
								ať je pro vás výše popsaný příběh minulostí.
							</p>
						</div>
					</div>
				</div>
				{this.getPreReleasedTalks() &&
					this.getPreReleasedTalks()
						.slice(0, 1)
						.map(talk => (
							<React.Fragment key={talk.id}>
								<hr />
								<div className="container-heading">
									<h3 className="mt-1 slash">Příští epizoda</h3>
								</div>
								<hr />
								<div className="container-heading talks">
									<div className="row justify-content-between">
										<div className="col-12 col-sm-6 order-1 order-sm-0">
											<Talk
												name={talk.name}
												thumbnail={talk.thumbnail}
												author={talk.author}
												date={new Date(talk.releaseDate).toLocaleString(
													"cs-CZ",
													{
														year: "numeric",
														month: "long",
														day: "numeric"
													}
												)}
											/>
										</div>
										<div className="col-12 col-sm-6 d-flex align-items-center justify-content-center order-0 order-sm-1">
											<Countdown
												width={100}
												height={100}
												radius={48}
												timeTillDate={talk.releaseDate}
											/>
										</div>
									</div>
								</div>
							</React.Fragment>
						))}
				<hr />
				<div className="container-heading">
					<h3 className="mt-1 slash">Minulé epizody</h3>
				</div>
				<hr />
				<div className="container-heading talks">
					<div className="row justify-content-between">
						{this.getReleasedTalks().map(talk => (
							<div key={talk.id} className="col-12 col-md-6 py-md-3">
								<a
									href={`#${talk.id}`}
									onClick={() => this.handleTalkChange(talk)}
								>
									<Talk
										name={talk.name}
										author={talk.author}
										date={new Date(talk.releaseDate).toLocaleString("cs-CZ", {
											year: "numeric",
											month: "long",
											day: "numeric"
										})}
										thumbnail={talk.thumbnail}
									/>
								</a>
							</div>
						))}
					</div>
				</div>
				<hr />
				<div className="container-heading">
					<h3 className="slash">Newsletter</h3>
				</div>
				<hr />

				<NewsLatter
					onError={error => {
						throw error;
					}}
					modalClassName={this.props.className}
				/>

				<hr />
				<div className="container-heading">
					<h3 className="slash">Partneři</h3>
				</div>
				<hr />
				<div className="container mt-5 mb-5">
					<div className="row justify-content-center align-items-center">
						{this.props.data.metadata.partners.map(partner => (
							<div
								key={partner.name}
								className="col-sm-12 col-12 col-md-3 text-center"
								style={partnersStyles}
							>
								<a
									href={partner.url_adress}
									rel="noopener noreferrer"
									target="_blank"
								>
									<ImgWrapper
										data={partner.image}
										className="img-fluid"
										alt={partner.name}
									/>
								</a>
							</div>
						))}
					</div>
				</div>
				<hr />
				<div className="container-heading">
					<h3 className="slash">Organizátor</h3>
				</div>
				<hr />
				<div className="container mt-5 mb-5">
					<div className="row justify-content-center">
						<div className="col-sm-4 col-8 text-center">
							<a
								href="https://www.megumethod.com/"
								rel="noopener noreferrer"
								target="_blank"
							>
								<img src={megumethod} className="img-fluid" alt="MeguMethod" />
							</a>
						</div>
					</div>
				</div>
			</Layout>
		);
	}
}

export default function(props) {
	return (
		<StaticQuery
			query={graphql`
				query {
					googlePlayImage: file(relativePath: { eq: "download-play.png" }) {
						name
						childImageSharp {
							fluid {
								...GatsbyImageSharpFluid
							}
						}
					}
					appleStoreImage: file(relativePath: { eq: "download-store.png" }) {
						name
						childImageSharp {
							fluid {
								...GatsbyImageSharpFluid
							}
						}
					}
					metadata: contentJson {
						title
						heading
						paragraphs
						partners {
							name
							url_adress
							image {
								childImageSharp {
									fluid {
										...GatsbyImageSharpFluid
									}
								}
							}
						}
						event {
							phase
							title
							paragraphs
							images {
								childImageSharp {
									fluid {
										...GatsbyImageSharpFluid
									}
								}
							}
							applications {
								app_name
								company_name
								icon {
									childImageSharp {
										fixed(width: 50, height: 50) {
											...GatsbyImageSharpFixed
										}
									}
								}
							}
							cena_creative_docku {
								app_name
								company_name
								icon {
									childImageSharp {
										fixed(width: 50, height: 50) {
											...GatsbyImageSharpFixed
										}
									}
								}
							}
						}
					}
				}
			`}
			render={data => <Talks data={data} {...props} />}
		/>
	);
}
