import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./countdown.style.scss";
import ImgWrapper from "gatsby-image-wrapper";
import iconRefresh from "../../images/icon-refresh.svg";

const Countdown = ({ width, height, timeTillDate, radius }) => {
	const [state, setState] = useState({
		days: 0,
		hours: 0,
		minutes: 0,
		seconds: 0,
		show: true
	});

	const widthCSS = `${width}px`;
	const heightCSS = `${height}px`;

	const countdownDate = new Date(timeTillDate).getTime();

	const timeoutCount = () => {
		const now = new Date().getTime();
		return countdownDate - now;
	};

	const updateCountdown = () => {
		const now = new Date().getTime();
		const countdown = countdownDate - now;
		const days = Math.floor(countdown / (1000 * 60 * 60 * 24));
		const hours = Math.floor(
			(countdown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
		);
		const minutes = Math.floor((countdown % (1000 * 60 * 60)) / (1000 * 60));
		const seconds = Math.floor((countdown % (1000 * 60)) / 1000);

		setState({
			days,
			hours,
			minutes,
			seconds,
			show: true
		});
	};

	useEffect(() => {
		const interval = setInterval(() => updateCountdown(), 1000);

		const timeout = setTimeout(() => {
			clearInterval(interval);
			setState({ show: false });
		}, timeoutCount());
		return () => {
			clearInterval(interval);
			clearTimeout(timeout);
		};
	}, []);

	// Mapping the date values to radius values
	const daysRadius = mapNumber(state.days, 31, 0, 0, 360);
	const hoursRadius = mapNumber(state.hours, 24, 0, 0, 360);
	const minutesRadius = mapNumber(state.minutes, 60, 0, 0, 360);
	const secondsRadius = mapNumber(state.seconds, 60, 0, 0, 360);

	return (
		<>
			{state.show ? (
				<div className="countdown-wrapper">
					{typeof state.days === "number" && state.days >= 0 && (
						<div
							className="countdown-item"
							style={{
								width: widthCSS,
								height: heightCSS,
								fontSize: `${(width / 100) * 40}px`
							}}
						>
							<SVGCircle
								radiusanime={daysRadius}
								radius={radius}
								width={width}
								height={height}
							/>
							{state.days}
							<span
								style={{
									fontSize: `${(width / 100) * 12}px`,
									paddingTop: width / 100 > 1 ? "0.8em" : 0
								}}
							>
								D
							</span>
						</div>
					)}
					{!state.hours >= 0 && (
						<div
							className="countdown-item"
							style={{
								width: widthCSS,
								height: heightCSS,
								fontSize: `${(width / 100) * 40}px`
							}}
						>
							<SVGCircle
								radiusanime={hoursRadius}
								radius={radius}
								width={width}
								height={height}
							/>
							{state.hours}
							<span
								style={{
									fontSize: `${(width / 100) * 12}px`,
									paddingTop: width / 100 > 1 ? "0.8em" : 0
								}}
							>
								H
							</span>
						</div>
					)}
					{!state.minutes >= 0 && (
						<div
							className="countdown-item"
							style={{
								width: widthCSS,
								height: heightCSS,
								fontSize: `${(width / 100) * 40}px`
							}}
						>
							<SVGCircle
								radiusanime={minutesRadius}
								radius={radius}
								width={width}
								height={height}
							/>
							{state.minutes}
							<span
								style={{
									fontSize: `${(width / 100) * 12}px`,
									paddingTop: width / 100 > 1 ? "0.8em" : 0
								}}
							>
								M
							</span>
						</div>
					)}
					{!state.seconds >= 0 && (
						<div
							className="countdown-item"
							style={{
								width: widthCSS,
								height: heightCSS,
								fontSize: `${(width / 100) * 40}px`
							}}
						>
							<SVGCircle
								radiusanime={secondsRadius}
								radius={radius}
								width={width}
								height={height}
							/>
							{state.seconds}
							<span
								style={{
									fontSize: `${(width / 100) * 12}px`,
									paddingTop: width / 100 > 1 ? "0.8em" : 0
								}}
							>
								S
							</span>
						</div>
					)}
				</div>
			) : (
				<div className="d-flex flex-column">
					<ImgWrapper
						className="m-3 pointer-event"
						style={{ width: "70px;", height: "70px", cursor: "pointer" }}
						data={iconRefresh}
						onClick={() => {
							location.reload();
						}}
					/>
					<span className="text-lg">Nový díl k dispozici!</span>
				</div>
			)}
		</>
	);
};

const SVGCircle = ({ radiusanime, radius, width, height }) => {
	const x = (width / 100) * 50;
	const y = (height / 100) * 50;
	const widthCSS = `${width}px`;
	const heightCSS = `${height}px`;
	return (
		<svg
			className="countdown-svg"
			style={{ width: widthCSS, height: heightCSS }}
		>
			<path
				fill="none"
				stroke="#fff"
				strokeWidth="1"
				d={describeArc(x, y, radius, 0, radiusanime)}
			/>
		</svg>
	);
};

// From StackOverflow: https://stackoverflow.com/questions/5736398/how-to-calculate-the-svg-path-for-an-arc-of-a-circle
function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
	const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

	return {
		x: centerX + radius * Math.cos(angleInRadians),
		y: centerY + radius * Math.sin(angleInRadians)
	};
}

function describeArc(x, y, radius, startAngle, endAngle) {
	const start = polarToCartesian(x, y, radius, endAngle);
	const end = polarToCartesian(x, y, radius, startAngle);

	const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

	const d = [
		"M",
		start.x,
		start.y,
		"A",
		radius,
		radius,
		0,
		largeArcFlag,
		0,
		end.x,
		end.y
	].join(" ");

	return d;
}

// From StackOverflow: https://stackoverflow.com/questions/10756313/javascript-jquery-map-a-range-of-numbers-to-another-range-of-numbers
function mapNumber(number, in_min, in_max, out_min, out_max) {
	return (
		((number - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min
	);
}

SVGCircle.propTypes = {
	width: PropTypes.string.isRequired,
	height: PropTypes.string.isRequired,
	radius: PropTypes.number.isRequired
};

Countdown.defaultProps = {
	width: 100,
	height: 100
};

Countdown.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
	timeTillDate: PropTypes.string.isRequired
};
export default Countdown;
