import React, { useEffect, useState } from "react";
import ImgWrapper from "gatsby-image-wrapper";
import loadingIcon from "../images/icon-loading.svg";

/*
<div id="presentation-embed-38930890"></div>
<script src='https://slideslive.com/embed_presentation.js'></script>
<script>
    embed = new SlidesLiveEmbed('presentation-embed-38930890', {
        presentationId: '38930890',
        autoPlay: false, // change to true to autoplay the embedded presentation
        verticalEnabled: true
    });
</script>
*/
const id = "slidesLive";

/**
 * Funkce pridava tag script na konec body. Pouziva plan javascript, zadny React.
 * Musel jsem pouzit plain javascript, protoze React nepodporuje onLoad na tagu script
 * @returns {Promise<unknown>}
 */
function loadScript() {
	const script = document.createElement("script");
	script.src = "https://slideslive.com/embed_presentation.js";
	script.id = id;
	const promise = new Promise((resolve, reject) => {
		script.onload = resolve;
		script.onerror = reject;
	});
	document.body.appendChild(script);

	return promise;
}

/**
 * Pokud v body existuje script odstrani ho
 */
function unloadScript() {
	const script = document.getElementById(id);
	if (script) document.body.removeChild(script);
}

export default function({ presentationId }) {
	const [isLoading, setIsLoading] = useState(true);
	// Nelze pouzit async primo na urovni useEffect viz https://medium.com/javascript-in-plain-english/how-to-use-async-function-in-react-hook-useeffect-typescript-js-6204a788a435
	useEffect(() => {
		async function asyncCall() {
			// nahraju script z externiho zdroje a pockam az bude nacten
			await loadScript();
			// zde si jsem jist, ze script definoval co to je SlidesLiveEmbed
			new SlidesLiveEmbed(`presentation-embed-${presentationId}`, {
				presentationId,
				autoPlay: false, // change to true to autoplay the embedded presentation
				verticalEnabled: true
			});
			setIsLoading(false);
		}

		asyncCall();

		// kdyz komponenta zmizi z webu, napriklad prejdu na jinou podstranku, odstranim vytvoreny script z body
		// musim ho odstranit, jinak by se mi tam hromadili. Napr /talks -> /historie -> /talks
		return () => {
			unloadScript();
		};
	}, []);

	return (
		<div className="talk__placeholder">
			{isLoading && (
				<ImgWrapper className="talk__placeholder__spinner" data={loadingIcon} />
			)}
			<div
				className={isLoading && "d-none"}
				id={`presentation-embed-${presentationId}`}
			/>
		</div>
	);
}
