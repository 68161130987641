import React, {useEffect, useState} from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import PropTypes from "prop-types";
import {Modal, ModalHeader} from "reactstrap";

const NewsLatterForm = ({
							onValidated,
							status,
							message,
							onInvalidData,
							afterSubmit,
							onSuccess,
							onError
						}) => {
	const [isSubmitting, setIsSubmitting] = useState(false);

	useEffect(() => {
		if (status === "success") {
			onSuccess();
			afterSubmit();
			setIsSubmitting(false);
		}
		if (status === "error") {
			onError();
			afterSubmit();
			setIsSubmitting(false);
		}
	}, [status, message]);

	const handleNewsletter = () => {
		const email = document.getElementById("subscription-email").value;
		const name = document.getElementById("subscription-name").value;
		const surname = document.getElementById("subscription-surname").value;
		const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if (re.test(email) && name.length > 0 && surname.length > 0) {
			setIsSubmitting(true);
			onValidated({EMAIL: email, FNAME: name, LNAME: surname});
		} else {
			onInvalidData();
		}
	};

	const handleSubmit = e => {
		e.preventDefault();
		handleNewsletter();
	};

	return (
		<form className="form-horizontal" onSubmit={handleSubmit}>
			<div className="form-group">
				<div>
					<input
						id="subscription-name"
						type="name"
						className="form-control form-input"
						placeholder="Jméno"
					/>
					<input
						id="subscription-surname"
						type="surname"
						className="form-control form-input"
						placeholder="Příjmení"
					/>
					<input
						id="subscription-email"
						type="email"
						className="form-control form-input"
						placeholder="Můj E-mail"
					/>
				</div>
				<div className="button-wrapper">
					<button
						type="button"
						className="btn btn-default btn-md button_newsletter"
						onClick={handleNewsletter}
						disabled={isSubmitting}
					>
						{isSubmitting ? <>Odesílám...</> : <>přihlásit se k odběru</>}
					</button>
				</div>
			</div>
		</form>
	);
};

NewsLatterForm.propTypes = {
	onValidated: PropTypes.func.isRequired,
	status: PropTypes.string,
	message: PropTypes.string,
	onInvalidEmail: PropTypes.func.isRequired,
	afterSubmit: PropTypes.func.isRequired,
	onSuccess: PropTypes.func.isRequired,
	onError: PropTypes.func.isRequired
};

NewsLatterForm.defaultProps = {
	status: "",
	message: ""
};

const NewsLatter = ({
						beforeSubmit,
						afterSubmit,
						onSuccess,
						onError,
						onInvalidData,
						modalClassName
					}) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [modalMessage, setModalMessage] = useState("");

	const mailchimpUrl =
		"https://synetech.us18.list-manage.com/subscribe/post?u=3e0de94c5a7f3336eddc3f93b&id=2c38a7dd40";

	const toggleModal = () => {
		setIsModalOpen(prevState => !prevState);
	};

	return (
		<>
			<Modal
				isOpen={isModalOpen}
				toggle={toggleModal}
				className={modalClassName}
			>
				<ModalHeader toggle={toggleModal}>{modalMessage}</ModalHeader>
			</Modal>
			<div className="container newsletter">
				<div className="row">
					<div className="col font-flexa-light">
						<p className="text-lg">
							Jak AppParade dopadla? Díky čemu jsou vítězné aplikace úspěšné?
							Dostaňte po AppParade exkluzivní pdf.
						</p>

						<MailchimpSubscribe
							url={mailchimpUrl}
							render={({subscribe, status, message}) => (
								<NewsLatterForm
									onValidated={formData => {
										beforeSubmit();
										subscribe(formData);
									}}
									status={status}
									message={message}
									onInvalidData={() => {
										setModalMessage("Prosim vyplnte všechny udaje");
										toggleModal();
										onInvalidData();
									}}
									afterSubmit={afterSubmit}
									onSuccess={() => {
										setModalMessage("Děkujeme");
										toggleModal();
										onSuccess();
									}}
									onError={error => {
										setModalMessage(error.message);
										toggleModal();
										onError(error);
									}}
								/>
							)}
						/>

						<br/>
					</div>
				</div>
			</div>
		</>
	);
};

NewsLatter.propTypes = {
	beforeSubmit: PropTypes.func,
	afterSubmit: PropTypes.func,
	onSuccess: PropTypes.func,
	onError: PropTypes.func,
	onInvalidData: PropTypes.func,
	// eslint-disable-next-line react/forbid-prop-types
	modalClassName: PropTypes.object
};

NewsLatter.defaultProps = {
	beforeSubmit: () => {
	},
	afterSubmit: () => {
	},
	onSuccess: () => {
	},
	onError: () => {
	},
	onInvalidData: () => {
	},
	modalClassName: {}
};

export default NewsLatter;
